import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { Breadcrumb, NavigationService } from '@common/services/navigation.service';
import { NavItem } from 'app/navigation';
import { ActionBarGroup } from './action-bar/action-bar.interface';

@Component({
    selector: 'app-content-header',
    template: `
        <div
            class="mb-2 flex flex-wrap items-start justify-between rounded-lg bg-card pb-1 shadow-md"
            id="content-header">
            <section class="flex flex-col gap-3 px-4">
                <div *ngIf="getBreadcrumbs().length" class="flex items-center gap-2 text-xs">
                    <a
                        class="flex items-center gap-1 text-foreground/40 hover:text-link"
                        aria-current="page"
                        [routerLink]="['/']">
                        <ng-icon kendoTooltip title="{{ 'Home' | translate }}" size="12px" name="faSolidHouse" />
                    </a>
                    <ng-container *ngFor="let item of getBreadcrumbs()">
                        <div *ngIf="item.icon" class="pt-1 text-foreground/10">
                            <ng-icon size="1rem" name="faSolidAngleRight" />
                        </div>
                        <a
                            class="flex items-center gap-1 text-foreground/40"
                            aria-current="page"
                            [ngClass]="{ 'flex gap-1': true, 'hover:text-link': item.route }"
                            [routerLink]="item.route ? [item.route] : null">
                            <ng-icon size="12px" [name]="isLast ? item.icon || 'defaultViewModeIcon' : item.icon" />
                            <div>{{ item.title.replace('-', ' ') | titlecase | translate }}</div>
                        </a>
                    </ng-container>
                </div>
            </section>
            <hr *ngIf="getBreadcrumbs().length" class="w-full border-background-darker" />
            <div class="flex w-full flex-wrap items-center justify-between px-4 py-2 text-foreground">
                <div class="flex flex-row items-center gap-3">
                    <ng-icon size="24px" [name]="icon ? icon : getCurrentNavItem()?.icon" />
                    <div>
                        <h1 class="text-lg font-bold tracking-[.01em] text-neutral-700 dark:text-neutral-400">
                            {{ (title ? title : getCurrentNavItem()?.title) | translate }}
                        </h1>
                        <ng-content select="[subtitle]" />
                    </div>
                    <ng-content select="[metadata-1]" />
                </div>
                <app-action-bar [items]="actionBar" />
            </div>
            <ng-content select="[metadata-2]" />
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderComponent {
    navigationService = inject(NavigationService);

    @Input() title: string;
    @Input() subtitle: string;
    @Input() icon: IconKey;
    @Input() actionBar: ActionBarGroup[];
    /**
     * Pass this if you want to override the breadcrumbs
     */
    @Input() breadcrumbs?: Breadcrumb[];
    /**
     * Pass this if you want to override the currentNavItem
     */
    @Input() currentNavItem: NavItem;

    getBreadcrumbs(): Breadcrumb[] {
        return this.breadcrumbs ?? this.navigationService.breadcrumbs();
    }

    getCurrentNavItem(): NavItem {
        return this.currentNavItem ?? this.navigationService.currentNavItem();
    }
}
